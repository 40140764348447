const TipoAvaliacaoRisco = Object.freeze({
  CRITERIO_QUANTITATIVO: 1,
  CRITERIO_QUALITATIVO: 2,
  FAIXA_DE_CONFORTO: 3
});


export const TipoAvaliacaoRiscoLabelEnum = new Map([
    [TipoAvaliacaoRisco.CRITERIO_QUANTITATIVO, 'Critério quantitativo'],
    [TipoAvaliacaoRisco.CRITERIO_QUALITATIVO, 'Critério qualitativo'],
    [TipoAvaliacaoRisco.FAIXA_DE_CONFORTO, 'Faixa de conforto'],
]);


export default TipoAvaliacaoRisco;
